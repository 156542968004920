<template>
  <div>
    <v-card-actions class="mt-4">
      <span class="text-body-1 font-weight-semibold">
        {{ title }}
      </span>

      <v-spacer></v-spacer>

      <div
        v-if="note"
        class="info--text"
        style="max-width:100px"
        :style="items.length === 0 ? 'margin-right: 26px' : ''"
      >
        {{ note }}
      </div>

      <v-btn
        v-if="items.length > 0"
        icon
        x-small
        @click="show = !show"
      >
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition v-if="items.length > 0">
      <div v-show="show">
        <v-card-text
          v-for="(item, key) in items"
          :key="key"
          class="align-center"
        >
          <div class="d-flex align-center">
            <v-icon
              small
              left
              color="info"
            >
              mdi-check
            </v-icon>
            <span>{{ item.title }}</span>
            <span
              v-if="item.note"
              class="ms-auto me-6 info--text"
            >{{ item.note }}</span>
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>
    <v-divider />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    note: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: true,
    }
  },
}
</script>
