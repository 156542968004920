<template>
  <v-card class="pt-1">
    <v-row class="mt-5 mb-3">
      <v-col>
        <div class="text-h5 font-weight-semibold text-center">
          GÓI SẢN PHẨM SIMPLEIVEST
        </div>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col
        offset="1"
        cols="10"
        offset-sm="2"
        sm="8"
        offset-md="3"
        md="2"
      >
        <v-btn
          width="100%"
          color="primary"
          :outlined="tab!==0"
          @click="tab=0"
        >
          Đầu tư lướt sóng
        </v-btn>
      </v-col>
      <v-col
        offset="1"
        cols="10"
        offset-sm="2"
        sm="8"
        offset-md="0"
        md="2"
      >
        <v-btn
          width="100%"
          color="primary"
          :outlined="tab!==1"
          @click="tab=1"
        >
          Đầu tư cơ bản
        </v-btn>
      </v-col>
      <v-col
        offset="1"
        cols="10"
        offset-sm="2"
        sm="8"
        offset-md="0"
        md="2"
      >
        <v-btn
          width="100%"
          color="primary"
          :outlined="tab!==2"
          @click="tab=2"
        >
          Khóa học đầu tư
        </v-btn>
        <v-col>
        </v-col>
      </v-col>
    </v-row>

    <v-row class="px-5">
      <v-col
        v-for="(plan, key) in plans"
        :key="key"
        cols="12"
        md="4"
        :offset="plans.length === 1 ? 3 : 0"
      >
        <v-card
          elevation="4"
          outlined
          class="mt-0 my-3"
          height="100%"
        >
          <PlanInfo
            :title="plan.title"
            :description="plan.description"
            :href="plan.href"
            :note="plan.note"
          />

          <PlanItem
            v-for="(item, itemKey) in plan.items"
            :key="itemKey"
            :title="item.title"
            :note="item.note"
            :items="item.children"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

import PlanItem from '../components/PlanItem.vue'
import PlanInfo from '../components/PlanInfo.vue'

export default {
  components: {
    PlanItem,
    PlanInfo,
  },

  data() {
    const free = {
      title: 'Trải nghiệm Free',
      description: ['0 VNĐ'],
      href: 'https://zalo.me/g/tfczzo816',
      items: [
        {
          title: 'Thị trường',
          children: [
            { title: 'Bản tin thị trường trước phiên GD' },
            { title: 'Cập nhật trạng thái thị trường trong phiên GD' },
            { title: 'Cập nhật các tin tức hot trên thị trường' },
          ],
        },
        {
          title: 'Phân tích',
          children: [
            { title: 'Phân tích tin tức, Breaking News' },
            { title: 'Phân tích vĩ mô' },
            { title: 'Phân tích ngành nghề' },
            { title: 'Phân tích cổ phiếu' },
          ],
        },
        {
          title: 'Các chỉ số FA cơ bản trên SI Pro',
          children: [
            { title: 'Hiệu quả kinh doanh' },
            { title: 'Cơ cấu lợi nhuận trước thuế' },
            { title: 'Lợi nhuận sau thuế trên mỗi cổ phiếu (EPS)' },
            { title: 'Cơ cấu tài sản' },
            { title: 'Cơ cấu nguồn vốn' },
            { title: 'Cấu trúc vốn và khả nằng thanh toán' },
          ],
        },
        {
          title: 'Quà tặng Ấn phẩm định giá chuyên sâu',
          note: 'Khi có chương trình',
          children: [
            { title: 'Tổng quan doanh nghiệp' },
            { title: 'Kết quả kinh doanh theo Quý' },
            { title: 'Điểm nhấn đầu tư' },
            { title: 'Định giá doanh nghiệp' },
            { title: 'Nhận định kỹ thuật (Điểm Mua)' },
          ],
        },
        {
          title: 'Khóa học miễn phí',
          children: [
            { title: 'Khóa học Phân tích Kỹ thuật cho người mới' },
            { title: 'Khóa học Phân tích Cơ bản cho người mới' },
          ],
        },
      ],
    }

    return {
      tab: 0,
      groups: [
        // Đầu tư lướt sóng
        [
          free,
          {
            title: 'Smart Trading (Basic)',
            description: ['1.200.000 VNĐ/tháng', 'hoặc Mở TK vốn trên 200 triệu VNĐ'],
            note: 'Bao gồm tính năng của gói Trải nghiệm Free',
            href: 'https://ldp.page/62996a8f1740480012692024',
            items: [
              {
                title: 'Tư vấn',
                children: [
                  { title: 'Room hỗ trợ đầu tư VIP' },
                  { title: 'Chuyên gia tư vấn đầu tư' },
                  { title: 'Hỗ trợ xử lý nghiệp vụ đầu tư' },
                  { title: 'Chuyên gia đồng hành đầu tư 1:1', note: 'Giới hạn thời gian' },
                ],
              },
              {
                title: 'Danh mục đầu tư',
                children: [
                  { title: 'Theo dòng tiền (PTKT) - Ngắn hạn' },
                  { title: 'Theo sóng thông tin (Breaking News) - Ngắn hạn' },
                  { title: 'Theo sóng ngành - Ngắn/Trung hạn' },
                ],
              },
              {
                title: 'Khuyến nghị mua/bán',
                children: [
                  { title: 'Hệ thống đi lệnh tự động - BOT Smart Trading: Ngắn hạn' },
                  { title: 'Lệnh Tư vấn từ Chuyên gia: Ngắn/Trung hạn' },
                ],
              },
            ],
          },
          {
            title: 'Cố vấn đầu tư (VIP)',
            description: ['3.900.000 VNĐ/tháng', 'hoặc Mở TK vốn trên 1 tỷ VNĐ'],
            note: 'Bao gồm tính năng của gói Smart Trading',
            href: 'https://ldp.page/629b5798b0e965004611fe7f',
            items: [
              {
                title: 'Phân tích nâng cao',
                children: [
                  { title: 'Báo cáo chiến lược mỗi quý' },
                ],
              },
              {
                title: 'Tư vấn chuyên sâu',
                children: [
                  { title: 'Chuyên gia đồng hành đầu tư 1:1', note: 'Full time' },
                ],
              },
              {
                title: 'Danh mục đầu tư nâng cáo',
                children: [
                  { title: 'Theo KQKD (PTCB) - Trung/Dài hạn' },
                ],
              },
              {
                title: 'Các chỉ số FA quan trọng trên SI Pro',
                children: [
                  { title: 'Chất lượng doanh thu và hàng tồn kho' },
                  { title: 'Người mua trả tiền trước và doanh thu chưa thực hiện' },
                  { title: 'Tiền ròng/Cổ phiếu' },
                  { title: 'Chi phí/Doanh thu' },
                  { title: 'Hiệu suất đầu tư' },
                  { title: 'Chỉ số phân tích khả năng sinh lời (Dupont)' },
                ],
              },
              {
                title: 'Công cụ biểu đồ so sánh FA',
                children: [
                  { title: 'So sánh tất cả các chỉ FA của 2 DN cùng ngành' },
                ],
              },
              {
                title: 'Công cụ bộ lọc nhanh FA',
                children: [
                  { title: 'Lọc các DN theo tiêu chuẩn Canslim' },
                  { title: 'Lọc các DN có Tích trữ hàng tồn kho giá rẻ' },
                  { title: 'Lọc các DN có lượng lớn giá trị dự án chờ hạch toán' },
                  { title: 'Lọc các DN có đầu tư lớn vào các nhà máy/dự án' },
                  { title: 'Lọc các DN đưa vào vận hành hiệu quả tài sản cố định' },
                  { title: 'Lọc các DN tăng trưởng ROE quý gần nhất' },
                  { title: 'Lọc các DN theo tỷ lệ tiền ròng/cổ phiếu' },
                ],
              },
              {
                title: 'Ấn phẩm định giá chuyên sâu siêu cổ',
                children: [
                  { title: 'Tổng quan doanh nghiệp' },
                  { title: 'Kết quả kinh doanh theo Quý' },
                  { title: 'Điểm nhấn đầu tư' },
                  { title: 'Định giá doanh nghiệp' },
                  { title: 'Nhận định kỹ thuật (Điểm Mua)' },
                ],
              },
            ],
          },
        ],
        // Đầu tư cơ bản
        [
          free,
          {
            title: 'Công cụ Phân tích FA - SI Pro',
            description: ['1.000.000 VNĐ/tháng'],
            note: 'Bao gồm tính năng của gói Trải nghiệm Free',
            href: 'https://ldp.page/63ac6a950c340d0012459195',
            items: [
              {
                title: 'Phân tích nâng cao',
                children: [
                  { title: 'Báo cáo chiến lược mỗi quý' },
                ],
              },
              {
                title: 'Tư vấn',
                children: [
                  { title: 'Room hỗ trợ đầu tư VIP' },
                  { title: 'Chuyên gia tư vấn đầu tư' },
                ],
              },
              {
                title: 'Các chỉ số FA quan trọng trên SI Pro',
                children: [
                  { title: 'Chất lượng doanh thu và hàng tồn kho' },
                  { title: 'Người mua trả tiền trước và doanh thu chưa thực hiện' },
                  { title: 'Tiền ròng/Cổ phiếu' },
                  { title: 'Chi phí/Doanh thu' },
                  { title: 'Hiệu suất đầu tư' },
                  { title: 'Chỉ số phân tích khả năng sinh lời (Dupont)' },
                ],
              },
              {
                title: 'Công cụ biểu đồ so sánh FA',
                children: [
                  { title: 'So sánh tất cả các chỉ FA của 2 DN cùng ngành' },
                ],
              },
              {
                title: 'Công cụ bộ lọc nhanh FA',
                children: [
                  { title: 'Lọc các DN theo tiêu chuẩn Canslim' },
                  { title: 'Lọc các DN có Tích trữ hàng tồn kho giá rẻ' },
                  { title: 'Lọc các DN có lượng lớn giá trị dự án chờ hạch toán' },
                  { title: 'Lọc các DN có đầu tư lớn vào các nhà máy/dự án' },
                  { title: 'Lọc các DN đưa vào vận hành hiệu quả tài sản cố định' },
                  { title: 'Lọc các DN tăng trưởng ROE quý gần nhất' },
                  { title: 'Lọc các DN theo tỷ lệ tiền ròng/cổ phiếu' },
                ],
              },
            ],
          },
          {
            title: 'Ấn phẩm định giá chuyên sâu',
            description: ['1.000.000 VNĐ/tháng'],
            note: 'Bao gồm tính năng của gói Trải nghiệm Free',
            href: 'https://ldp.page/6291ba05fd2364002d19f8cc',
            items: [
              {
                title: 'Phân tích nâng cao',
                children: [
                  { title: 'Báo cáo chiến lược mỗi quý' },
                ],
              },
              {
                title: 'Tư vấn',
                children: [
                  { title: 'Room hỗ trợ đầu tư VIP' },
                  { title: 'Chuyên gia tư vấn đầu tư' },
                ],
              },
              {
                title: 'Ấn phẩm định giá chuyên sâu siêu cổ',
                children: [
                  { title: 'Tổng quan doanh nghiệp' },
                  { title: 'Kết quả kinh doanh theo Quý' },
                  { title: 'Điểm nhấn đầu tư' },
                  { title: 'Định giá doanh nghiệp' },
                  { title: 'Nhận định kỹ thuật (Điểm Mua)' },
                ],
              },
            ],
          },
        ],
        // Khóa học đầu tư
        [
          free,
          {
            title: 'Khóa học Phân tích cơ bản',
            description: ['10.000.000 VNĐ/lớp'],
            note: 'Bao gồm tính năng của gói Trải nghiệm Free',
            href: 'https://ldp.page/63bb7f6b0b73d60020167ca5',
            items: [
              {
                title: 'Phân tích nâng cao',
                children: [
                  { title: 'Báo cáo chiến lược mỗi quý' },
                ],
              },
              {
                title: 'Tư vấn',
                children: [
                  { title: 'Room hỗ trợ đầu tư VIP' },
                  { title: 'Chuyên gia tư vấn đầu tư' },
                  { title: 'Hỗ trợ xử lý nghiệp vụ đầu tư' },
                  { title: 'Chuyên gia đồng hành đầu tư 1:1', note: 'Giới hạn thời gian' },
                ],
              },
              {
                title: 'Danh mục đầu tư',
                children: [
                  { title: 'Theo dòng tiền (PTKT) - Ngắn hạn' },
                  { title: 'Theo sóng thông tin (Breaking News) - Ngắn hạn' },
                  { title: 'Theo sóng ngành - Ngắn/Trung hạn' },
                  { title: 'Theo KQKD (PTCB) - Trung/Dài hạn' },
                ],
                note: '1 tháng',
              },
              {
                title: 'Các chỉ số FA quan trọng trên SI Pro',
                children: [
                  { title: 'Chất lượng doanh thu và hàng tồn kho' },
                  { title: 'Người mua trả tiền trước và doanh thu chưa thực hiện' },
                  { title: 'Tiền ròng/Cổ phiếu' },
                  { title: 'Chi phí/Doanh thu' },
                  { title: 'Hiệu suất đầu tư' },
                  { title: 'Chỉ số phân tích khả năng sinh lời (Dupont)' },
                ],
                note: '3 tháng',
              },
              {
                title: 'Công cụ biểu đồ so sánh FA',
                children: [
                  { title: 'So sánh tất cả các chỉ FA của 2 DN cùng ngành' },
                ],
                note: '3 tháng',
              },
              {
                title: 'Công cụ bộ lọc nhanh FA',
                children: [
                  { title: 'Lọc các DN theo tiêu chuẩn Canslim' },
                  { title: 'Lọc các DN có Tích trữ hàng tồn kho giá rẻ' },
                  { title: 'Lọc các DN có lượng lớn giá trị dự án chờ hạch toán' },
                  { title: 'Lọc các DN có đầu tư lớn vào các nhà máy/dự án' },
                  { title: 'Lọc các DN đưa vào vận hành hiệu quả tài sản cố định' },
                  { title: 'Lọc các DN tăng trưởng ROE quý gần nhất' },
                  { title: 'Lọc các DN theo tỷ lệ tiền ròng/cổ phiếu' },
                ],
                note: '3 tháng',
              },
              {
                title: 'Ấn phẩm định giá chuyên sâu siêu cổ',
                children: [
                  { title: 'Tổng quan doanh nghiệp' },
                  { title: 'Kết quả kinh doanh theo Quý' },
                  { title: 'Điểm nhấn đầu tư' },
                  { title: 'Định giá doanh nghiệp' },
                  { title: 'Nhận định kỹ thuật (Điểm Mua)' },
                ],
                note: '2 bài',
              },
              {
                title: 'Nội dung khóa học cơ bản',
                children: [
                  { title: 'Vĩ mô tác động đến TTCK' },
                  { title: 'Đọc vị BCTC bằng công cụ SI PRO' },
                  { title: 'Nhận diện trường phái đầu tư tối ưu nhất' },
                  { title: 'Áp dụng BOT Bộ lọc nhanh FA' },
                  { title: 'Nhận diện "Thủ thuật gian lận" trong BCTC' },
                ],
              },
            ],
          },
          {
            title: 'Phân tích cơ bản thực chiến',
            description: ['15.000.000 VNĐ/lớp'],
            note: 'Bao gồm tính năng của Khóa học Phân tích cơ bản',
            href: 'https://ldp.page/63bb7f6b0b73d60020167ca5',
            items: [
              {
                title: 'Nội dung khóa học chuyên sâu',
                children: [
                  { title: 'Đọc vị tất cả ngành nghề' },
                  { title: 'Phân tích kỹ thuật thực chiến' },
                  { title: 'Định giá doanh nghiệp' },
                ],
              },
            ],
          },
        ],
      ],
    }
  },

  computed: {
    plans() {
      return this.groups[this.tab]
    },
  },
}

</script>
