<template>
  <div>
    <v-card-title class="justify-center">
      <a
        :href="href"
        target="_blank"
        class="text-h5 font-weight-semibold"
        style="text-decoration: none"
      >
        {{ title }}
        <v-icon
          small
          color="primary"
        >
          mdi-open-in-new
        </v-icon>
      </a>
    </v-card-title>
    <v-card-text
      class="text-center"
      style="min-height:125px"
    >
      <div
        v-for="(text, key) in description"
        :key="key"
        class="text-h6"
      >
        {{ text }}
      </div>

      <div class="text-subtitle-1 font-weight-semibold mt-2 info--text">
        {{ note }}
      </div>
    </v-card-text>
    <v-divider></v-divider>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: Array,
      required: true,
    },
    href: {
      type: String,
      default: '/',
    },

    note: {
      type: String,
      default: '',
    },
  },
}
</script>
